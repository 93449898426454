import React, {useEffect} from "react"
import {graphql} from "gatsby"
import {Container, Col, Row} from "react-bootstrap"
import LayoutOrig from "../../../components/layout_fr"
import Markdown from "react-markdown"
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Heading from "../../../components/heading"
import Seo from "../../../components/seo"
import { GatsbyImage } from "gatsby-plugin-image";
import slugger from "github-slugger"

/**
 * This renders an item in the table of contents list.
 * scrollIntoView is used to ensure that when a user clicks on an item, it will smoothly scroll.
 */

const API_URL = process.env.API_URL;

const Headings = ({ headings, activeId }) => (
    <ul>
      {headings.map((heading) => (
          <li key={heading.id} className={heading.id === activeId ? "active" : ""}>
            <a
                style={{fontSize:"14px"}}
                href={`#${heading.id}`}
                onClick={(e) => {
                  e.preventDefault();
                  document.querySelector(`#${heading.id}`).scrollIntoView({
                    behavior: "smooth"
                  });
                }}
            >
              {heading.title}
            </a>
            {heading.items.length > 0 && (
                <ul>
                  {heading.items.map((child) => (
                      <li
                          key={child.id}
                          className={child.id === activeId ? "active" : ""}
                      >
                        <a
                            href={`#${child.id}`}
                            onClick={(e) => {
                              e.preventDefault();
                              document.querySelector(`#${child.id}`).scrollIntoView({
                                behavior: "smooth"
                              });
                            }}
                        >
                          {child.title}
                        </a>
                      </li>
                  ))}
                </ul>
            )}
          </li>
      ))}
    </ul>
);

/**
 * Dynamically generates the table of contents list, using any H2s and H3s it can find in the main text
 */
const useHeadingsData = () => {
  const [nestedHeadings, setNestedHeadings] = React.useState([]);

  React.useEffect(() => {
    const headingElements = Array.from(
        document.querySelectorAll("main h2, main h3")
    );

    // Created a list of headings, with H3s nested
    const newNestedHeadings = getNestedHeadings(headingElements);
    setNestedHeadings(newNestedHeadings);
  }, []);

  return { nestedHeadings };
};

const getNestedHeadings = (headingElements) => {
  const nestedHeadings = [];

  headingElements.forEach((heading, index) => {
    const { innerText: title, id } = heading;

    if (heading.nodeName === "H2") {
      nestedHeadings.push({ id, title, items: [] });
    } else if (heading.nodeName === "H3" && nestedHeadings.length > 0) {
      nestedHeadings[nestedHeadings.length - 1].items.push({
        id,
        title
      });
    }
  });

  return nestedHeadings;
};

const useIntersectionObserver = (setActiveId) => {
  const headingElementsRef = React.useRef({});
  React.useEffect(() => {
    const callback = (headings) => {
      headingElementsRef.current = headings.reduce((map, headingElement) => {
        map[headingElement.target.id] = headingElement;
        return map;
      }, headingElementsRef.current);

      // Get all headings that are currently visible on the page
      const visibleHeadings = [];
      Object.keys(headingElementsRef.current).forEach((key) => {
        const headingElement = headingElementsRef.current[key];
        if (headingElement.isIntersecting) visibleHeadings.push(headingElement);
      });

      const getIndexFromId = (id) =>
          headingElements.findIndex((heading) => heading.id === id);

      // If there is only one visible heading, this is our "active" heading
      if (visibleHeadings.length === 1) {
        setActiveId(visibleHeadings[0].target.id);
        // If there is more than one visible heading,
        // choose the one that is closest to the top of the page
      } else if (visibleHeadings.length > 1) {
        const sortedVisibleHeadings = visibleHeadings.sort(
            (a, b) => getIndexFromId(a.target.id) > getIndexFromId(b.target.id)
        );

        setActiveId(sortedVisibleHeadings[0].target.id);
      }
    };

    const observer = new IntersectionObserver(callback, { root: document.querySelector("iframe"), rootMargin: "10px" });

    const headingElements = Array.from(document.querySelectorAll("h2","h3"));

    headingElements.forEach((element) => observer.observe(element));

    return () => observer.disconnect();
  }, [setActiveId]);
};

/**
 * Renders the table of contents.
 */
const TableOfContents = () => {
  const [activeId, setActiveId] = React.useState();
  const { nestedHeadings } = useHeadingsData();
  useIntersectionObserver(setActiveId);

  return (
      <nav aria-label="Table of contents" className="toc">
        <Headings headings={nestedHeadings} activeId={activeId} />
      </nav>
  )
}






const ArticleTemplate = ({ data }) => {

  useEffect(() => window.scrollTo(0, 0), []);

  /*useEffect(() => {
    try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
  }, [pathname, search]);*/

  return(
      <LayoutOrig>



          {data.first.edges.map(document => (
              <Seo
                  key={document.node.id}
                  titleTemplate="Les sociétes de services numériques (ESN) face au défi de la gestion de trésorerie | Trezorino"
                  title="Les sociétes de services numériques (ESN) face au défi de la gestion de trésorerie | Trezorino"
                  description="Une gestion de trésorerie efficace permet d'éviter les insuffisances de trésorerie temporaires et garantir la pérennité de l'entreprise"
                  image={document.node.HeaderImage.url}
                  lang="fr"
              />
        ))}
        {/*
<Helmet>
        <title>{data.strapiArticles.metaTitle}</title>
        <meta name="url" content={`${CONFIG.BASE_URL}/fr/blog/article/${data.strapiArticles.Url}`}/>
        <meta property="og:url" content={`${CONFIG.BASE_URL}/fr/blog/article/${data.strapiArticles.Url}`} />
        <meta property="og:type" content="article"/>

        {data.strapiArticles.HeaderImage.map( ArticleImage=> (
        <meta key={ArticleImage.id}  property="og:image" content={`${CONFIG.STRAPI_ROOT_URL}${ArticleImage.url}`}/>
        ))}

         {data.strapiArticles.HeaderImage.map( ArticleImageTwitter=> (
        <meta key={ArticleImageTwitter.id}  property="twitter:image" content={`${CONFIG.STRAPI_ROOT_URL}${ArticleImageTwitter.url}`}/>
        ))}
         </Helmet>*/}

        <div className="header-box-article" style={{backgroundColor: "#F9F9F9"}}>
          <Container className="justify-content-center pb-5">
            {data.first.edges.map(document => (
                <Row key={document.node.id}>
                  <Col md={{span: 2, offset: 0}} className="justify-content-center"></Col>
                  <Col md={{span: 6, offset: 0}}>
                    <div className="justify-content-center" style={{
                      backgroundColor: "#FFFFFF",
                      maxWidth: "720px",
                      boxShadow: "rgb(0 0 0 / 5%) 0px 0px 10px 0px",
                      borderRadius: "10px",
                      padding: "2rem",
                      paddingBottom: "2rem"
                    }}>

                      <Row className="pt-1 pb-2">
                        <h1 className="pt-4 pb-2">{document.node.Title}</h1>
                        <Col md={2}>
                          {/* <GatsbyImage image={document.node.author.photo.localFile.childImageSharp.gatsbyImageData}
                                   alt="text a définir"/>*/}

                        </Col>
                        {/*}   <Col md={10}>
                      <Row style={{fontSize: "16px"}}>
                        {document.node.author.username}
                      </Row>
                      <Row style={{fontSize: "14px"}}>
                        {document.node.updated_at}
                      </Row>
                    </Col>*/}


                      </Row>
                      <Row>
                        <picture>
                          <GatsbyImage key={document.node.HeaderImage.id}  image={document.node.HeaderImage.localFile.childImageSharp.gatsbyImageData} alt="text a définir"/>
                        </picture>
                      </Row>


                      <Markdown
                          children={document.node.Content}
                          components={{
                            h2: ({node, children, level}) => {

                              var nodeValue = children[0]
                              nodeValue= nodeValue.replace(/<(\/)?[^>]+>/g, "").replace(/\s{2,}/g, " ")

                              //nodeValue = nodeValue.replace("?", " ");
                              //nodeValue = nodeValue.replace(":", " ");
                              nodeValue = nodeValue.toLowerCase();
                              nodeValue= nodeValue.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
                              //nodeValue = nodeValue.trim();
                              //nodeValue = nodeValue.replace(/[^a-zA-Z0-9]/g, '-');

                              nodeValue = slugger.slug(nodeValue)

                              //console.log(" Après nodeValue="+nodeValue)

                              return (
                                  <Heading level={`h${level}`} id={nodeValue}>
                                    <AnchorLink preload to={`#${nodeValue}`} title={children[0]}>

                                    </AnchorLink>
                                  </Heading>
                              );
                            },
                            h3: ({node, children, level}) => {

                              var nodeValue = children[0]
                              nodeValue= nodeValue.replace(/<(\/)?[^>]+>/g, "").replace(/\s{2,}/g, " ")

                              //nodeValue = nodeValue.replace("?", " ");
                              //nodeValue = nodeValue.replace(":", " ");
                              nodeValue = nodeValue.toLowerCase();
                              nodeValue= nodeValue.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
                              //nodeValue = nodeValue.trim();
                              //nodeValue = nodeValue.replace(/[^a-zA-Z0-9]/g, '-');

                              nodeValue = slugger.slug(nodeValue)

                              console.log(" Après nodeValue="+nodeValue)

                              return (
                                  <Heading level={`h${level}`} id={nodeValue}>
                                    <AnchorLink to={`#${nodeValue}`} title={children[0]}>

                                    </AnchorLink>
                                  </Heading>
                              );
                            },
                          }}
                          skipHtml={false}
                          className="articleContent"
                          rehypePlugins={[rehypeRaw]}
                          remarkPlugins={[remarkGfm]}
                          transformImageUri={uri => uri.startsWith('http') ? uri : `${API_URL}${uri}`}
                      />


                      {/*<Reactmarkdown
                          source={data.strapiArticles.Content}
                          escapeHtml={false}
                          renderers={{
                            heading: HeadingBlock
                          }}
                          transformImageUri={uri =>
                            uri.startsWith("http") ? uri : `${CONFIG.STRAPI_ROOT_URL}${uri}`
                          }
                        />*/}


                    </div>
                  </Col>
                  <Col>
                    <div style={{position: "relative"}}>
                      <Col style={{position: "fixed", right: "0%", width: "34%",}}>
                        <Row className="pt-3 pl-3 pb-3">
                          <strong>{document.node.Title}</strong>
                        </Row>
                        <TableOfContents ></TableOfContents>
                      </Col>
                    </div>
                  </Col>
                </Row>

            ))}
          </Container>
        </div>
      </LayoutOrig>
  )};

export default ArticleTemplate;

export const data = graphql`
query Esn{
  first : allStrapiUseCases(filter: {id: {eq: "Use-cases_1"}}) {
  edges {
      node {
        id
        Title
        Content
        HeaderImage {
          id
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE)
            }
          }
        }
       }
      }
    }
  }
`;
